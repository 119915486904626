<template>
  <b-modal
    id="corp-modal"
    title="Field CORP"
    size="xmd"
    scrollable
    ok-only
    title-tag="h3"
    ok-title="Save"
    v-model="showModal"
    @hidden="handleClose"
  >
    <b-card-text class="px-1">
      <div v-if="disabledForm" class="d-flex justify-content-end">
        <b-button variant="secondary" @click="exportToPdf">
          <amg-icon size="18" class="text-danger" icon="FilePdfIcon" />
          <strong>Generate PDF</strong>
        </b-button>
      </div>
      <h4 class="p-1 mt-1 bg-dark rounded text-center mb-2">
        Client Information
      </h4>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Full name</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="clientInfo.name_client" disabled />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Birth Date</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <kendo-datepicker
                id="dateDob"
                v-model="clientInfo.birth"
                style="width: 21.3em !important"
                class="claims-datepicker"
                disabled
                :format="'MM/dd/yyyy'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>{{ clientInfo.ssn ? "SSN:" : "ITIN" }}</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-if="clientInfo.ssn"
                v-model="clientInfo.data_ssn"
                :disabled="disabled"
              />
              <b-form-input
                v-else
                :v-model="clientInfo.data_itin"
                :disabled="disabled"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Phone number</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="clientInfo.mobile" disabled />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Email</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="clientInfo.email" disabled />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Mailing Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="mailAdr"
                id="clint_mailAdr"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressClientInfoMailing"
                country="us"
                v-model="clientInfo.client_mailing_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      clientInfo,
                      'client_mailing_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Principal Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="mainAdr"
                id="cli_mainAdr"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressClientInfoPrincipal"
                country="us"
                v-model="clientInfo.client_principal_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      clientInfo,
                      'client_principal_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Gross Income</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input-group-prepend is-text>
                <feather-icon icon="DollarSignIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="clientInfo.income" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!--:::::::::::::BUSINESS INFORMATION:::::::::::::-->
      <h4 class="p-1 bg-dark rounded text-center my-2">Business Information</h4>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Type of Business</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.type_business" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>How long has the business been opearting?</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.years_company" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col :sm="6">
          <b-form-group class="text-center">
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Have you ever got an EIN as sole proprietorship?</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-radio-group
                v-model="businessInfo.ein_proprietorship"
                class="form-control text-center bg-transparent"
              >
                <template>
                  <b-form-radio value="YES"
                    ><span style="margin-left: 5px">Yes</span></b-form-radio
                  >
                  <b-form-radio value="NO"
                    ><span style="margin-left: 5px">No</span></b-form-radio
                  >
                </template>
              </b-radio-group>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="6" v-if="businessInfo.ein_proprietorship == 'YES'">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>EIN</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.ein" v-mask="'##-#######'" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Entity type</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.entity_type" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Stocks</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.stocks" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>How many people will manage the business?</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.people_to_manage" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="12">
          <h6 class="pt-1 text-center"><u>Business Name</u></h6>
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend class="w-35">
                    <b-input-group-text class="w-100 badge-name-group">
                      <span>First Option</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input v-model="businessInfo.business_name_one" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend class="w-35">
                    <b-input-group-text class="w-100 badge-name-group">
                      <span>Second Option</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input v-model="businessInfo.business_name_two" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Mailing Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="bus_mailing"
                id="bus_mailing"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressBusinessInfoMailing"
                country="us"
                v-model="businessInfo.mailing_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(e, businessInfo, 'mailing_address')
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Principal Office Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="princ_off"
                id="princ_off"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressBusinessInfoPrincipal"
                country="us"
                v-model="businessInfo.principal_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      businessInfo,
                      'principal_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!--:::::::::::::EXECUTIVE:::::::::::::-->
      <h4 class="p-1 bg-dark rounded text-center">Shareholders</h4>
      <h6 class="pt-1 text-center"><u>Chief Executive Officer</u></h6>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Fullname</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="executive.sha_full_name" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Birth Date</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <kendo-datepicker
                id="dateDob"
                v-model="executive.sha_birth_date"
                style="width: 21.3em !important"
                class="claims-datepicker"
                :format="'MM/dd/yyyy'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>SSN</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="executive.sha_ssn"
                v-mask="'###-##-####'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Phone number</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="executive.sha_phone_number"
                v-mask="'(###) ###-####'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Email</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="executive.sha_email" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Mailing Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="exec_mailing"
                id="exec_mailing"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressExecutiveMailing"
                country="us"
                v-model="executive.sha_mailing_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(e, executive, 'sha_mailing_address')
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Principal Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="exec_principal"
                id="exec_principal"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressExecutivePrincipal"
                country="us"
                v-model="executive.sha_principal_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      executive,
                      'sha_principal_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!--:::::::::::::FINANCIAL:::::::::::::-->
      <h6 class="pt-1 text-center"><u>Chief Financial Officer</u></h6>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Fullname</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="financial.sha_full_name" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Birth Date</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <kendo-datepicker
                id="dateDob"
                v-model="financial.sha_birth_date"
                style="width: 21.3em !important"
                class="claims-datepicker"
                :format="'MM/dd/yyyy'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>SSN</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="financial.sha_ssn"
                v-mask="'###-##-####'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Phone number</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="financial.sha_phone_number"
                v-mask="'(###) ###-####'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Email</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="financial.sha_email" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Mailing Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="financial_mailing"
                id="financial_mailing"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressFinancialMailing"
                country="us"
                v-model="financial.sha_mailing_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(e, financial, 'sha_mailing_address')
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Principal Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="financial_principal"
                id="financial_principal"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressFinancialPrincipal"
                country="us"
                v-model="financial.sha_principal_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      financial,
                      'sha_principal_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!--:::::::::::::SECRETARY:::::::::::::-->
      <h6 class="pt-1 text-center"><u>Secretary</u></h6>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Fullname</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="secretary.sha_full_name" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Birth Date</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <kendo-datepicker
                id="dateDob"
                v-model="secretary.sha_birth_date"
                style="width: 21.3em !important"
                class="claims-datepicker"
                :format="'MM/dd/yyyy'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>SSN</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="secretary.sha_ssn"
                v-mask="'###-##-####'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Phone number</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="secretary.sha_phone_number"
                v-mask="'(###) ###-####'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Email</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="secretary.sha_email" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Mailing Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="secreta_mailing"
                id="secreta_mailing"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressSecretaryMailing"
                country="us"
                v-model="secretary.sha_mailing_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(e, secretary, 'sha_mailing_address')
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Principal Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="secretary_principal"
                id="secretary_principal"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressSecretaryPrincipal"
                country="us"
                v-model="secretary.sha_principal_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      secretary,
                      'sha_principal_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click="saveForm" class="mr-auto"
          >Save</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  emits: ["whenClose", "whenOk"],
  data() {
    return {
      disabled: true,
      showModal: true,
      clientInfo: {
        name_client: null,
        birth: null,
        ssn: null,
        mobile: null,
        email: null,
        client_mailing_address: null,
        client_principal_address: null,
        income: null,
        data_ssn: null,
        data_itin: null,
      },
      disabledForm: false,
      businessInfo: {
        type_business: null,
        years_company: null,
        ein: null,
        entity_type: null,
        stocks: null,
        people_to_manage: null,
        business_name_one: null,
        business_name_two: null,
        mailing_address: null,
        principal_address: null,
      },
      executive: {
        title: "CHIEF EXECUTIVE OFFICER",
        sha_full_name: null,
        sha_birth_date: null,
        sha_ssn: null,
        sha_phone_number: null,
        sha_email: null,
        sha_mailing_address: null,
        sha_principal_address: null,
        member_id: null,
      },
      financial: {
        title: "CHIEF FINANCIAL OFFICER",
        sha_full_name: null,
        sha_birth_date: null,
        sha_ssn: null,
        sha_phone_number: null,
        sha_email: null,
        sha_mailing_address: null,
        sha_principal_address: null,
        member_id: null,
      },
      secretary: {
        title: "SECRETARY",
        sha_full_name: null,
        sha_birth_date: null,
        sha_ssn: null,
        sha_phone_number: null,
        sha_email: null,
        sha_mailing_address: null,
        sha_principal_address: null,
        member_id: null,
      },
      arrayShareholders: [],
    };
  },
  computed: {
    ...mapGetters({
      accountId: "ParagonClientDashboard/getAccountId",
    }),
  },
  async created() {
    await this.getDataClient();
  },
  methods: {
    handleClose() {
      this.$emit("whenClose");
    },
    handleOk() {
      this.$emit("whenOk");
    },
    onChangePrincipalMember(event, data, name) {
      this.$set(data, name, event.target.value);
    },
    async exportToPdf() {
      this.addPreloader();
      try {
        const data = await ClientDashboardService.generatePdfBusinessClient({
          accountid: this.accountId,
          type: 1,
        });
        if (data.status === 200) {
          await this.forceFileDownloadPdf(data.data);
        }
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },

    // async dowloadUsing(IMG_URL, FILE_NAME) {
    //   const image = IMG_URL;
    //   const imageBlog = new Blob([image], {
    //     type: "pdf/bu_bussiness",
    //   });
    //   const imageURL = URL.createObjectURL(imageBlog);

    //   const anchor = document.createElement("a");
    //   anchor.href = imageURL;
    //   anchor.download = FILE_NAME;

    //   document.body.appendChild(anchor);
    //   anchor.click();
    //   document.body.removeChild(anchor);
    //   URL.revokeObjectURL(imageURL);
    // },
    async getDataClient() {
      try {
        this.addPreloader();
        const dataClient = await ClientDashboardService.getDataClient({
          id_account: this.accountId,
        });

        if (dataClient.status === 200) {
          this.businessInfo = dataClient.data[0];
          if (this.businessInfo.completed) {
            this.disabledForm = true;
          } else {
            this.disabledForm = false;
          }
          //Clients Info
          this.clientInfo.name_client = this.businessInfo.name_client;
          this.clientInfo.birth = moment(this.businessInfo.birth).format(
            "MM/DD/YYYY"
          );
          this.clientInfo.ssn = this.businessInfo.ssn;
          this.clientInfo.mobile = this.businessInfo.mobile;
          this.clientInfo.email = this.businessInfo.email;
          this.clientInfo.client_mailing_address =
            this.businessInfo.client_mailing_address;
          this.clientInfo.client_principal_address =
            this.businessInfo.client_principal_address;
          this.clientInfo.shareholders = this.businessInfo.shareholders.length;
          this.clientInfo.data_ssn = this.businessInfo.data_ssn;
          this.clientInfo.data_itin = this.businessInfo.data_itin;
          this.clientInfo.income = this.businessInfo.client_gross_income;

          //Business Info
          //this.businessInfo.type = this.businessInfo.type_business;

          //Executive Info
          this.executive = this.businessInfo.shareholders[0]
            ? this.businessInfo.shareholders[0]
            : this.executive;

          this.executive.sha_birth_date =
            this.executive.sha_birth_date != null
              ? this.executive.sha_birth_date
              : null;

          //Financial Info
          this.financial = this.businessInfo.shareholders[1]
            ? this.businessInfo.shareholders[1]
            : this.financial;
          this.financial.sha_birth_date =
            this.financial.sha_birth_date != null
              ? this.financial.sha_birth_date
              : null;
          //Secretary Info
          this.secretary = this.businessInfo.shareholders[2]
            ? this.businessInfo.shareholders[2]
            : this.secretary;
          this.secretary.sha_birth_date =
            this.secretary.sha_birth_date != null
              ? this.secretary.sha_birth_date
              : null;
        }
        this.removePreloader();
      } catch (e) {
        console.log(e);
        this.showErrorSwal();
        this.removePreloader();
      }
    },
    getAddressClientInfoMailing: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.clientInfo.client_mailing_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressClientInfoPrincipal: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.clientInfo.client_principal_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressBusinessInfoMailing: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.businessInfo.mailing_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressBusinessInfoPrincipal: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.businessInfo.principal_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressExecutiveMailing: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.executive.sha_mailing_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressExecutivePrincipal: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.executive.sha_principal_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressFinancialMailing: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.financial.sha_mailing_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressFinancialPrincipal: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.financial.sha_principal_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressSecretaryMailing: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.secretary.sha_mailing_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressSecretaryPrincipal: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.secretary.sha_principal_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    async saveForm() {
      const swal = await this.showConfirmSwal(
        "Are you sure?",
        "You won't be able to revert this!"
      );
      if (swal.isConfirmed) {
        this.addPreloader();
        this.arrayShareholders = [
          this.executive,
          this.financial,
          this.secretary,
        ];
        try {
          const params = {
            f_account_id: this.accountId,
            f_type_business: this.businessInfo.type_business,
            f_year_company: this.businessInfo.years_company,
            f_checkEin: this.businessInfo.ein_proprietorship,
            f_ein_business: this.businessInfo.ein,
            f_entity_type: this.businessInfo.entity_type,
            f_stock: this.businessInfo.stocks,
            f_people: this.businessInfo.people_to_manage,
            f_first_option: this.businessInfo.business_name_one,
            f_second_option: this.businessInfo.business_name_two,
            f_mailing_address: this.businessInfo.mailing_address,
            f_principal_office_address: this.businessInfo.principal_address,
            f_array_shareholders: this.arrayShareholders,
            c_mailing_address: this.clientInfo.client_mailing_address,
            c_principal_address: this.clientInfo.client_principal_address,
            c_gross_income: this.clientInfo.income,
            typee: 1,
            iddeletemember: [],
            completedform: 1,
          };
          const response = await ClientDashboardService.saveFormCompany(params);
          if (response.status === 200) {
            this.showToast("success", "top-right", "Updated!", "CheckIcon");
            this.$emit("whenOk");
            this.removePreloader();
          }
        } catch (e) {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon"
            // this.getInternalErrors(error)
          );
          this.removePreloader();
        }
      }
    },
  },
};
</script>