<template>
  <b-modal
    id="corp-modal"
    title="Field LLC"
    size="xmd"
    scrollable
    ok-only
    title-tag="h3"
    ok-title="Save"
    v-model="showModal"
    @hidden="handleClose"
  >
    <b-card-text class="px-1">
      <div v-if="disabledForm" class="d-flex justify-content-end">
        <b-button variant="secondary" @click="exportToPdf">
          <amg-icon size="18" class="text-danger" icon="FilePdfIcon" />
          <strong>Generate PDF</strong>
        </b-button>
      </div>
      <h4 class="p-1 mt-1 bg-dark rounded text-center mb-1">
        Client Information
      </h4>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Full name</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="clientInfo.name_client"
                :disabled="disabled"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Birth Date</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <kendo-datepicker
                id="dateDob"
                v-model="clientInfo.birth"
                style="width: 21.3em !important"
                class="claims-datepicker"
                :disabled="disabled"
                :format="'MM/dd/yyyy'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>{{ clientInfo.ssn ? "SSN:" : "ITIN" }}</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                :v-model="
                  clientInfo.ssn ? clientInfo.data_ssn : clientInfo.data_itin
                "
                :disabled="disabled"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Phone number</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="clientInfo.mobile" :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Email</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="clientInfo.email" :disabled="disabled" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Mailing Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="addressmailingclient"
                id="addressmailingclient"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressDataMailingClient"
                country="us"
                v-model="clientInfo.client_mailing_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      clientInfo,
                      'client_mailing_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Principal Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="addressprincipalclient"
                id="addressprincipalclient"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressDataPrincipalClient"
                country="us"
                v-model="clientInfo.client_principal_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      clientInfo,
                      'client_principal_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <h4 class="p-1 bg-dark rounded text-center">Business Information</h4>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Type of Business</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.type_business" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>How long has the business been opearting?</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.years_company" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Have you ever got an EIN as sole proprietorship?</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-radio-group
                v-model="businessInfo.ein_proprietorship"
                class="form-control text-center bg-transparent"
                :style="bigWindow ? '' : 'height: 4rem;'"
              >
                <template>
                  <b-form-radio value="YES"
                    ><span style="margin-left: 5px">Yes</span></b-form-radio
                  >
                  <b-form-radio value="NO"
                    ><span style="margin-left: 5px">No</span></b-form-radio
                  >
                </template>
              </b-radio-group>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group v-if="businessInfo.ein_proprietorship === 'YES'">
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>EIN:</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.ein" v-mask="'##-#######'" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Entity type</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.entity_type" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>How many people will manage the business?</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="businessInfo.people_to_manage" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <h6 class="pt-1 text-center"><u>Business Name</u></h6>
          <b-row>
            <b-col sm="12">
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend class="w-35">
                    <b-input-group-text class="w-100 badge-name-group">
                      <span>First Option</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input v-model="businessInfo.business_name_one" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group>
                <b-input-group>
                  <b-input-group-prepend class="w-35">
                    <b-input-group-text class="w-100 badge-name-group">
                      <span>Second Option</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input v-model="businessInfo.business_name_two" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Mailing Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="addressMailingBusiness"
                id="addressMailingBusiness"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                v-on:placechanged="getAddressDataMailingBusiness"
                country="us"
                v-model="businessInfo.mailing_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(e, businessInfo, 'mailing_address')
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Principal Office Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="addresPrincipalBusiness"
                id="addresPrincipalBusiness"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                v-on:placechanged="getAddressDataPrincipalBusiness"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      businessInfo,
                      'principal_address'
                    )
                "
                country="us"
                v-model="businessInfo.principal_address"
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <h4 class="p-1 bg-dark rounded text-center">Members</h4>
      <h6 class="pt-1 text-center"><u>Create Member</u></h6>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Fullname</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="newMember.sha_full_name" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Birth Date</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <kendo-datepicker
                id="dateDob"
                v-model="newMember.sha_birth_date"
                style="width: 21.3em !important"
                class="claims-datepicker"
                :format="'MM/dd/yyyy'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>SSN</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="newMember.sha_ssn"
                v-mask="'###-##-####'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Phone number</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="newMember.sha_phone_number"
                v-mask="'(###) ###-####'"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Email</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="newMember.sha_email" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Mailing Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="addressMailingMember"
                id="addressMailingMember"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressDataMailingMember"
                :key="key.mail_member"
                country="us"
                v-model="newMember.sha_mailing_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(e, newMember, 'sha_mailing_address')
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-35">
                <b-input-group-text class="w-100 badge-name-group">
                  <span>Principal Address</span>
                </b-input-group-text>
              </b-input-group-prepend>
              <vue-google-autocomplete
                ref="addressPrincipalMember"
                id="addressPrincipalMember"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                @placechanged="getAddressDataPrincipalMember"
                country="us"
                :key="key.principal_member"
                v-model="newMember.sha_principal_address"
                @keyup="
                  (e) =>
                    onChangePrincipalMember(
                      e,
                      newMember,
                      'sha_principal_address'
                    )
                "
              ></vue-google-autocomplete>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="d-flex justify-content-end px-1" sm="6">
          <b-button class="mr-1 mb-1" variant="danger" @click="clearMemberForm"
            >Clear</b-button
          >
          <b-button
            v-if="this.newMember.index != null"
            class="mb-1"
            variant="info"
            @click="addMember"
            >Update Member
          </b-button>
          <b-button v-else class="mb-1" variant="success" @click="addMember"
            >Add member
          </b-button>
        </b-col>
      </b-row>
      <h6 class="pt-1 text-center"><u>Member List</u></h6>
      <b-row>
        <b-col>
          <b-table
            responsive="sm"
            small
            :items="clientInfo.shareholders"
            :fields="membersFields"
            show-empty
          >
            <template #empty>
              <h4 class="text-center">There are no members to show</h4>
            </template>
            <template #cell(sha_birth_date)="data">
              <span>{{ data.value | myGlobal }}</span>
            </template>
            <template #cell(action)="data">
              <div class="d-flex justify-content-between">
                <b-button
                  variant="warning"
                  class="btn-icon"
                  size="sm"
                  @click="updateMember(data.index, data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="danger"
                  class="btn-icon"
                  size="sm"
                  @click="deleteMember(data.index, data.item)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card-text>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click="saveForm" class="mr-auto"
          >Save</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";
import moment from "moment";
export default {
  components: {
    VueGoogleAutocomplete,
  },
  emits: ["whenClose", "whenOk"],
  data() {
    return {
      showModal: true,
      clientInfo: {
        name_client: null,
        birth: null,
        ssn: null,
        mobile: null,
        email: null,
        client_mailing_address: null,
        client_principal_address: null,
        shareholders: [],
        data_ssn: null,
        data_itin: null,
      },
      disabledForm: false,
      key: {
        principal_member: false,
        mail_member: false,
      },
      disabled: true,
      businessInfo: {
        type_business: null,
        years_company: null,
        ein: null,
        entity_type: null,
        stocks: null,
        people_to_manage: null,
        nameFirst: null,
        nameSecond: null,
        mailAdr: null,
        mainAdr: null,
      },
      newMember: {
        title: "MEMBER",
        sha_full_name: null,
        sha_birth_date: null,
        sha_ssn: null,
        sha_phone_number: null,
        sha_email: null,
        sha_mailing_address: null,
        sha_principal_address: null,
        index: null,
        member_id: null,
      },
      arrayDeletedMember: [],
      membersFields: [
        { key: "sha_full_name", label: "Name" },
        { key: "sha_birth_date", label: "Birth date" },
        { key: "sha_ssn", label: "SSN" },
        { key: "sha_phone_number", label: "Phone" },
        { key: "sha_email", label: "Email" },
        { key: "sha_mailing_address", label: "Mailing Address" },
        { key: "sha_principal_address", label: "Principal Address" },
        { key: "action", label: "Action" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      bigWindow: "app/bigWindow",
      currentUser: "auth/currentUser",
      client: "ParagonClientDashboard/getPersonalInputs",
      clientName: "ParagonClientDashboard/getClient",
      accountId: "ParagonClientDashboard/getAccountId",
    }),
  },
  async created() {
    await this.getDataClient();
  },
  methods: {
    handleClose() {
      this.$emit("whenClose");
    },
    onChangePrincipalMember(event, data, name) {
      this.$set(data, name, event.target.value);
    },
    async getDataClient() {
      try {
        this.addPreloader();
        const dataClient = await ClientDashboardService.getDataClient({
          id_account: this.accountId,
        });
        if (dataClient.status === 200) {
          this.businessInfo = dataClient.data[0];
          this.businessInfo.shareholders.forEach((item) => {
            item.sha_birth_date = moment(item.sha_birth_date).format(
              "MM/DD/YYYY"
            );
          });
          if (this.businessInfo.completed) {
            this.disabledForm = true;
          } else {
            this.disabledForm = false;
          }
          this.clientInfo.name_client = this.businessInfo.name_client;
          this.clientInfo.birth = moment(this.businessInfo.birth).format(
            "MM/DD/YYYY"
          );
          this.clientInfo.ssn = this.businessInfo.ssn;
          this.clientInfo.mobile = this.businessInfo.mobile;
          this.clientInfo.email = this.businessInfo.email;
          this.clientInfo.client_mailing_address =
            this.businessInfo.client_mailing_address;
          this.clientInfo.client_principal_address =
            this.businessInfo.client_principal_address;
          this.clientInfo.shareholders = this.businessInfo.shareholders;
          this.clientInfo.data_ssn = this.businessInfo.data_ssn;
          this.clientInfo.data_itin = this.businessInfo.data_itin;
        }
        this.removePreloader();
      } catch (e) {
        console.log(e);
        this.showErrorSwal();
        this.removePreloader();
      }
    },
    async exportToPdf() {
      this.addPreloader();
      try {
        const data = await ClientDashboardService.generatePdfBusinessClient({
          accountid: this.accountId,
          type: 1,
        });
        if (data.status === 200) {
          await this.forceFileDownloadPdf(data.data);
        }
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },

    // async dowloadUsing(IMG_URL, FILE_NAME) {
    //   const image = IMG_URL;
    //   const imageBlog = new Blob([image], {
    //     type: "pdf/bu_bussiness",
    //   });
    //   const imageURL = URL.createObjectURL(imageBlog);

    //   const anchor = document.createElement("a");
    //   anchor.href = imageURL;
    //   anchor.download = FILE_NAME;

    //   document.body.appendChild(anchor);
    //   anchor.click();
    //   document.body.removeChild(anchor);
    //   URL.revokeObjectURL(imageURL);
    // },
    clearMemberForm() {
      this.newMember = {
        title: "MEMBER",
        sha_full_name: null,
        sha_birth_date: null,
        sha_ssn: null,
        sha_phone_number: null,
        sha_email: null,
        sha_mailing_address: null,
        sha_principal_address: null,
        index: null,
        member_id: null,
      };
      this.key = {
        principal_member: !this.key.principal_member,
        mail_member: !this.key.mail_member,
      };
    },
    getAddressDataMailingClient: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.clientInfo.client_mailing_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressDataPrincipalClient: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.clientInfo.client_principal_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressDataMailingBusiness: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.businessInfo.mailing_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressDataPrincipalBusiness: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.businessInfo.principal_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
    },
    getAddressDataMailingMember: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.newMember.sha_mailing_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
      this.key = {
        principal_member: !this.key.principal_member,
        mail_member: !this.key.mail_member,
      };
    },
    getAddressDataPrincipalMember: function (address_create_card_modal) {
      const direction = address_create_card_modal;
      this.newMember.sha_principal_address =
        direction.street_number +
        " " +
        direction.route +
        ", " +
        direction.locality +
        ", " +
        direction.administrative_area_level_1 +
        " " +
        direction.postal_code;
      this.key = {
        principal_member: !this.key.principal_member,
        mail_member: !this.key.mail_member,
      };
    },
    addMember() {
      if (this.newMember.index !== null) {
        this.clientInfo.shareholders[this.newMember.index] = Object.assign(
          {},
          this.newMember
        );
        this.clientInfo.shareholders = JSON.parse(
          JSON.stringify(this.clientInfo.shareholders)
        );
      } else {
        this.clientInfo.shareholders.push({
          title: "MEMBER",
          sha_full_name: this.newMember.sha_full_name,
          sha_birth_date: this.newMember.sha_birth_date,
          sha_ssn: this.newMember.sha_ssn,
          sha_phone_number: this.newMember.sha_phone_number,
          sha_email: this.newMember.sha_email,
          sha_mailing_address: this.newMember.sha_mailing_address,
          sha_principal_address: this.newMember.sha_principal_address,
          member_id: this.newMember.member_id,
        });
      }
      this.key = {
        principal_member: !this.key.principal_member,
        mail_member: !this.key.mail_member,
      };
      this.clientInfo.shareholders = JSON.parse(
        JSON.stringify(this.clientInfo.shareholders)
      );
      this.clearMemberForm();
    },
    updateMember(index, item) {
      this.newMember = Object.assign({}, item);
      this.newMember.index = index;
      this.key = {
        principal_member: !this.key.principal_member,
        mail_member: !this.key.mail_member,
      };
    },
    async saveForm() {
      const swal = await this.showConfirmSwal(
        "Are you sure?",
        "You won't be able to revert this!"
      );
      if (swal.isConfirmed) {
        this.addPreloader();
        try {
          const params = {
            f_account_id: this.accountId,
            f_type_business: this.businessInfo.type_business,
            f_year_company: this.businessInfo.years_company,
            f_checkEin: this.businessInfo.ein_proprietorship,
            f_ein_business: this.businessInfo.ein,
            f_entity_type: this.businessInfo.entity_type,
            f_stock: this.businessInfo.stocks,
            f_people: this.businessInfo.people_to_manage,
            f_first_option: this.businessInfo.business_name_one,
            f_second_option: this.businessInfo.business_name_two,
            f_mailing_address: this.businessInfo.mailing_address,
            f_principal_office_address: this.businessInfo.principal_address,
            f_array_shareholders: this.clientInfo.shareholders,
            c_mailing_address: this.clientInfo.client_mailing_address,
            c_principal_address: this.clientInfo.client_principal_address,
            c_gross_income: "",
            typee: 2,
            iddeletemember: this.arrayDeletedMember,
            completedform: 1,
          };
          const response = await ClientDashboardService.saveFormCompany(params);
          if (response.status === 200) {
            this.showToast("success", "top-right", "Updated!", "CheckIcon");
            this.$emit("whenOk");
            this.removePreloader();
          }
        } catch (e) {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(e)
          );
          this.removePreloader();
        }
      }
    },
    deleteMember(index, item) {
      this.clientInfo.shareholders.splice(index, 1);
      if (item.member_id) {
        this.arrayDeletedMember.push(item.member_id);
      }
    },
  },
};
</script>